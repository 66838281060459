import React, { useEffect } from 'react'
import { SelectInputBox } from 'helpers/forms'
import { isEmpty } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { fetchStoreItemGroups, fetchTransactionTypes } from 'store/actions/services.actions'

const TransactionType = ({ control, name, required, value }) => {
  const { transactionTypes } = useSelector((state) => state.enums)
  const dispatch = useDispatch()
  const transactionTypesOptions = React.useMemo(() => {
    return isEmpty(transactionTypes) ? [] : transactionTypes.map((type) => ({ label: type.name, value: type.id }))
  }, [transactionTypes])
  useEffect(() => {
    isEmpty(transactionTypes) && dispatch(fetchTransactionTypes())
  }, [dispatch, transactionTypes])
  return (
    <SelectInputBox
      label="TransactionType"
      required={required}
      options={transactionTypesOptions}
      control={control}
      name={name}
      defaultValue={value}
    />
  )
}

export const ItemGroup = ({ control, name, required, value }) => {
  const { storeItemGroups } = useSelector((state) => state.services.storeItemGroups)
  const dispatch = useDispatch()

  const itemGroupOptions = React.useMemo(() => {
    const options = isEmpty(storeItemGroups)
      ? []
      : storeItemGroups.map((type) => ({ label: type.storeItemGroupDescription, value: type.storeItemGroupId }))
    options.unshift({ label: 'None', value: -1 })
    return options
  }, [storeItemGroups])

  const getGroupItems = React.useCallback(() => {
    dispatch(fetchStoreItemGroups())
  }, [dispatch])

  useEffect(() => {
    isEmpty(storeItemGroups) && getGroupItems()
  }, [getGroupItems, storeItemGroups])

  return (
    <SelectInputBox
      label="Item Group"
      required={required}
      options={itemGroupOptions}
      control={control}
      name={name}
      defaultValue={value}
    />
  )
}

export default TransactionType
