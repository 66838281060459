import { makeStyles, Switch, Table, TableBody, TableCell, TableRow, TextField, Typography } from '@material-ui/core'
import { TIMEZONES } from 'constants/timezones'
import { COUNTRY_CODES } from 'constants/contry-codes'
import { US_STATES } from 'constants/us-states'
import {
  BookingServicesOptions,
  DatePickerBox,
  DecimalField,
  InvoiceTypesOptions,
  NetworkOptions,
  SponsorPaymentModelOptions,
  SponsorTypeOptions,
} from 'helpers/forms'
import { GetFormattedByType } from 'helpers/getFormattedByType'
import { isEmpty, isFunction, find } from 'lodash'
import { AmenityAutocompleteBox } from 'modules/autocompletes/AmenityAutocompleteBox'
import { BrandsAutocompleteBox } from 'modules/autocompletes/BrandsAutocompleteBox'
import { BudgetsAutocompleteBox } from 'modules/autocompletes/BudgetsAutocompleteBox'
import { NetworksAutocompleteBox } from 'modules/autocompletes/NetworksAutocompleteBox'
import { TagsAutocompleteBox } from 'modules/autocompletes/TagsAutocompleteBox'
import TransactionType, { ItemGroup } from 'modules/autocompletes/TransactionType'
import React from 'react'
import { Controller } from 'react-hook-form'
import { CompanyTypes } from 'services/types'

const useStyles = makeStyles(() => ({
  head: ({ labelWidth = '50%', border, verticalAlign = 'top' }) => ({
    width: labelWidth,
    borderBottom: border,
    verticalAlign,
  }),
  cell: ({ border, verticalAlign = 'top' }) => ({
    borderBottom: border,
    verticalAlign,
  }),
}))

const DataCardRows = ({
  data,
  rows,
  dense = false,
  hover = false,
  labelsAlign = 'left',
  padding,
  isEdit,
  control,
  watch,
  errors,
  columnCount = 1,
  ...props
}) => {
  const classes = useStyles(props)

  const getFieldByType = (data, row, watchAllFields) => {
    switch (row.type) {
      case 'textarea':
        return (
          <Controller
            fullWidth
            as={TextField}
            name={row.id}
            control={control}
            multiline
            minRows={4}
            variant="outlined"
            size="small"
            required={row.required}
          />
        )
      case 'decimal':
        return (
          <Controller
            as={DecimalField}
            defaultValue={data[row.id]}
            name={row.id}
            control={control}
            variant="outlined"
            size="small"
            required={row.required}
          />
        )
      case 'binary':
      case 'boolean':
        return (
          <Controller
            control={control}
            name={row.id}
            variant="outlined"
            size="small"
            required={row.required}
            defaultValue={data[row.id]}
            render={(props) => {
              return (
                <Switch
                  onChange={(e) => props.onChange(!!e.target.checked)}
                  defaultChecked={!!props.value || !!data[row.id] || false}
                />
              )
            }}
          />
        )

      case 'reverse_boolean':
        return (
          <Controller
            control={control}
            name={row.id}
            variant="outlined"
            size="small"
            required={row.required}
            defaultValue={!data[row.id]}
            render={(props) => {
              return (
                <Switch
                  onChange={(e) => props.onChange(!e.target.checked)}
                  defaultChecked={!props.value || !data[row.id] || false}
                />
              )
            }}
          />
        )
      case 'booleanWithConfirm':
        return (
          <Controller
            control={control}
            name={row.id}
            variant="outlined"
            size="small"
            required={row.required}
            defaultValue={data[row.id]}
            render={(props) => {
              return <>{row.component()}</>
            }}
          />
        )
      case 'networks':
        return (
          <Controller
            control={control}
            as={
              <TextField>
                {NetworkOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            }
            name={row.id}
            variant="outlined"
            size="small"
            select
            SelectProps={{ native: true }}
            value={data[row.id]}
            required={row.required}
          />
        )
      case 'booking_service':
        return (
          <Controller
            control={control}
            as={
              <TextField>
                {BookingServicesOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            }
            name={row.id}
            variant="outlined"
            size="small"
            select
            SelectProps={{ native: true }}
            value={data[row.id]}
            required={row.required}
          />
        )
      case 'datepicker':
        return (
          <Controller
            control={control}
            as={
              <DatePickerBox
                control={control}
                //errors={errors}
                name={row.id}
                //label={row.id}
                // defaultValue={""}
              />
            }
            name={row.id}
            variant="outlined"
            size="small"
            select
            SelectProps={{ native: true }}
            value={data[row.id]}
            required={row.required}
          />
        )
      case 'invoice_type':
        return (
          <Controller
            control={control}
            as={
              <TextField>
                {InvoiceTypesOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            }
            name={row.id}
            variant="outlined"
            size="small"
            select
            SelectProps={{ native: true }}
            value={data[row.id]}
            required={row.required}
          />
        )
      case 'sponsor_type':
        return (
          <Controller
            control={control}
            as={
              <TextField>
                {SponsorTypeOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            }
            name={row.id}
            variant="outlined"
            size="small"
            select
            SelectProps={{ native: true }}
            value={data[row.id]}
            required={row.required}
          />
        )
      case 'companyTypeId':
        return (
          <Controller
            control={control}
            as={
              <TextField>
                {CompanyTypes.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </TextField>
            }
            name={row.id}
            variant="outlined"
            size="small"
            select
            SelectProps={{ native: true }}
            value={data[row.id]}
            required={row.required}
          />
        )
      case 'studio_group':
        return (
          <Controller
            as={TextField}
            name={'studio_group.id'}
            control={control}
            variant="outlined"
            size="small"
            required={row.required}
          />
        )
      case 'state':
        return watchAllFields?.[row?.countryKey] !== 'US' ? (
          <Controller
            as={TextField}
            defaultValue={data[row.id]}
            name={row.id}
            control={control}
            variant="outlined"
            size="small"
            required={row.required}
          />
        ) : (
          <Controller
            control={control}
            as={
              <TextField>
                {US_STATES.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            }
            name={row.id}
            variant="outlined"
            size="small"
            select
            SelectProps={{ native: true }}
            value={data[row.id]}
            required={row.required}
          />
        )
      case 'country':
        return (
          <Controller
            control={control}
            as={
              <TextField>
                {COUNTRY_CODES.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            }
            name={row.id}
            variant="outlined"
            size="small"
            select
            SelectProps={{ native: true }}
            value={data[row.id]}
            required={row.required}
          />
        )

      case 'timezone':
        return (
          <Controller
            control={control}
            as={
              <TextField>
                {TIMEZONES.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            }
            name={row.id}
            variant="outlined"
            size="small"
            select
            SelectProps={{ native: true }}
            value={data[row.id]}
            required={row.required}
          />
        )
      case 'brand':
        return <BrandsAutocompleteBox control={control} name={row.id} required={row.required} />
      case 'network':
        return <NetworksAutocompleteBox control={control} name={row.id} required={row.required} />
      case 'budget':
        return <BudgetsAutocompleteBox control={control} name={row.id} required={row.required} />
      case 'tagobjects':
        return <TagsAutocompleteBox control={control} name={row.id} required={row.required} />
      case 'amenityobjects':
        return <AmenityAutocompleteBox control={control} name={row.id} required={row.required} />
      case 'payment_model':
        return (
          <Controller
            control={control}
            as={
              <TextField>
                {SponsorPaymentModelOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            }
            name={row.id}
            variant="outlined"
            size="small"
            select
            SelectProps={{ native: true }}
            value={data[row.id]}
            required={row.required}
          />
        )
      case 'transaction_type':
        return <TransactionType control={control} name={row.id} required={row.required} value={data[row.id]} />
      case 'item_group':
        return <ItemGroup control={control} name={row.id} required={row.required} value={data[row.id]} />
      case 'postal_code':
        return (
          <Controller
            name={row.id}
            control={control}
            defaultValue={data[row.id]}
            rules={
              watchAllFields?.[row?.countryKey] !== 'US'
                ? undefined
                : {
                    pattern: {
                      value: /^\d{5}$/,
                    },
                  }
            }
            as={
              <TextField
                variant="outlined"
                size="small"
                required={row.required}
                error={!!errors?.[row.id]}
                helperText={errors?.[row.id]?.message}
              />
            }
          />
        )
      default:
        return (
          <Controller
            as={TextField}
            defaultValue={data[row.id]}
            name={row.id}
            control={control}
            variant="outlined"
            size="small"
            required={row.required}
          />
        )
    }
  }
  const size = Math.ceil(rows.length / columnCount)
  const rowsChunk = Array.from({ length: columnCount }, (v, i) => rows.slice(i * size, i * size + size))
  const watchAllFields = watch?.()

  const renderTableByColumnCount = (rowsChunk) => {
    return rowsChunk.map((rows, index) => {
      return (
        <Table
          key={index}
          className={classes.tableBody}
          {...(dense && { size: 'small' })}
          style={{ width: `${Math.floor(100 / columnCount)}%` }}
        >
          <TableBody>
            {!isEmpty(data) &&
              rows.map((row, index) => {
                const label = row.label

                const formattedValue = <GetFormattedByType item={data} col={row} watchAllFields={watchAllFields} />
                const fieldByType = getFieldByType(data, row, watchAllFields)

                const isEditable = isEdit && (isFunction(row?.editable) ? row?.editable(data) : row?.editable)

                if (row.visibleWatch && (!row.watchDuringEdit || row.watchDuringEdit === isEditable)) {
                  let flag = true
                  if (row.visibleWatchFunction) {
                    const watchValue = find(
                      row.visibleWatch,
                      (field, index) => !row.visibleWatchFunction[index](watchAllFields?.[field])
                    )
                    if (watchValue) flag = false
                  } else {
                    if (find(row.visibleWatch, (field) => !watchAllFields?.[field])) flag = false
                  }

                  if (!flag) return null
                }

                return (
                  <TableRow className={classes.row} key={`${row.id}-${index}`} hover={hover}>
                    <TableCell className={classes.head} align={labelsAlign} padding={padding}>
                      <Typography variant="h6">{label}</Typography>
                    </TableCell>
                    <TableCell className={classes.cell}>{isEditable ? fieldByType : formattedValue}</TableCell>
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      )
    })
  }

  return <div style={{ display: 'flex' }}>{renderTableByColumnCount(rowsChunk)}</div>
}
// merge conflict

export default DataCardRows
