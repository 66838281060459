import { combineReducers } from '@reduxjs/toolkit'

import global from 'store/reducers/global.reducer'
import auth from 'store/reducers/auth.reducer'
import enums from 'store/reducers/enums.reducer'
import brands from 'store/reducers/brands.reducer'
import budgets from 'store/reducers/budgets.reducer'
import regions from 'store/reducers/regions.reducer'
import featureFlags from 'store/reducers/featureFlags.reducer'
import storeItems from 'store/reducers/storeItems.reducer'
import reservations from 'store/reducers/reservations.reducer'
import sponsors from 'store/reducers/sponsors.reducer'
import users from 'store/reducers/users.reducer'
import store from 'store/reducers/store.reducer'
import studios from 'store/reducers/studios.reducer'
import studioGroups from 'store/reducers/studioGroups.reducer'
import stripe from 'store/reducers/stripe.reducer'
import transactions from 'store/reducers/transactions.reducer'
import promotions from 'store/reducers/promotions.reducer'
import consumer from 'store/reducers/consumer.reducer'
import challenges from 'store/reducers/challenges.reducer'
import storeItemGroups from 'store/reducers/storeItemGroups.reducer'

const rootReducer = combineReducers({
  global,
  auth,
  enums,
  services: combineReducers({
    brands,
    budgets,
    consumer,
    challenges,
    promotions,
    regions,
    featureFlags,
    reservations,
    sponsors,
    store,
    stripe,
    studios,
    studioGroups,
    storeItems,
    transactions,
    users,
    storeItemGroups,
  }),
})

export default rootReducer
