import { createSlice } from '@reduxjs/toolkit'

import { fetchStoreItemGroups } from 'store/actions/services.actions'

const storeItemGroupsSlice = createSlice({
  name: 'storeItemGroups',
  initialState: {
    isLoading: false,
    status: null,
    errors: null,
    itemGroup: null,
    storeItemGroups: [],
  },
  reducers: {
    clearStoreItemGroups: (state) => ({ ...state, itemGroup: null }),
  },
  extraReducers: {
    [`${fetchStoreItemGroups.pending}`]: (state, action) => ({ ...state, isLoading: true, errors: null }),
    [`${fetchStoreItemGroups.rejected}`]: (state, action) => ({
      ...state,
      isLoading: false,
      errors: action?.error?.message,
    }),
    [`${fetchStoreItemGroups.fulfilled}`]: (state, action) => {
      return { ...state, isLoading: false, storeItemGroups: [...action.payload] }
    },
  },
})

export const storeItemGroupsActions = storeItemGroupsSlice.actions

export default storeItemGroupsSlice.reducer
